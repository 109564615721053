import React from 'react'
import AboutComponent from '../components/AboutComponent'
const About = () => {
  return (
    <div id='about'>
      <AboutComponent/>
    </div>
  )
}

export default About
