import React from "react";
import HomeComponent from "../components/HomeComponent";
const Home = () => {
  return (
    <div id="home">
      <HomeComponent />
    </div>
  );
};

export default Home;
