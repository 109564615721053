import React from "react";
import AppFeature from "../../src/components/AppFeatures";
const appfeature = () => {
  return (
    <div>
      <AppFeature />
    </div>
  );
};

export default appfeature;
