import React from "react";
import BlogComponent from "../components/BlogComponent";
const Blog = () => {
  return (
    <div id="blog">
      <BlogComponent/>
    </div>
  );
};

export default Blog;
