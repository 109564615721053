import React from 'react'
import FooterComponent from '../components/FooterComponent'
const Footer = () => {
  return (
    <div>
    <FooterComponent/>
    </div>
  )
}

export default Footer